import React, { FC, FormEvent, useEffect, useState } from 'react';
import { ChoiceSelection, GroupedAnswer, ProgressScope } from '../../interfaces/api.types';
import { ActivitiesGetInvalids } from './form.utils';
import { InOut, InputNumber, SpanBody2, SpanCaption1 } from '@linkeo.com/ui-lib-react';
import { ActivityQuestion } from '../questions/activity-question';
import { QuestionChoices } from '../../interfaces/question.types';
import { FormFooter } from './form-footer';
import { PaddingForCard } from '../ui/padding-for-card';
import { useIntl } from 'react-intl';


interface ActivityFormProps {
  context: ProgressScope;
  onAnswer: (answer: ProgressScope) => void;
  onPrevious: () => void;
}

export const ActivityForm: FC<ActivityFormProps> = ({ context, onAnswer, onPrevious }) => {
  const [getCurrentGroup, setCurrentGroup] = useState<GroupedAnswer>();
  const intl = useIntl();

  useEffect(() => {
    if (!context.activityGroup) {
      return;
    }
    setCurrentGroup(context.activityGroup);
  }, [context]);

  const checkValidity = (): boolean => {
    if (!getCurrentGroup) {
      return false;
    }
    const invalids = ActivitiesGetInvalids(getCurrentGroup);
    setCurrentGroup({
      ...getCurrentGroup,
      isInvalid: invalids,
    });
    return !invalids;
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();
    if (!getCurrentGroup) {
      return;
    }
    setCurrentGroup({
      ...getCurrentGroup,
      isInvalid: false,
    });
    if (!checkValidity()) {
      return;
    }
    let responsesTexte = getCurrentGroup.responsesTexte;
    let inputData = getCurrentGroup.inputData;

    if (getCurrentGroup.question.type === 'SINGLE_CHOICE' || getCurrentGroup.question.type === 'MULTIPLE_CHOICE') {
      if (getCurrentGroup.inputData && getCurrentGroup.booleanFirst) {
        responsesTexte = (getCurrentGroup.inputData as ChoiceSelection[]).map(cs =>
          `${cs.choice.label}${cs.choice.addChoiceQuantity ? ` - ${cs.quantity}${cs.choice.choiceQuantityUnit}` : ''}`);
      } else {
        responsesTexte = [intl.formatMessage({ id: 'no', defaultMessage: 'Non' })];
        inputData = [];
      }
    }
    if (!inputData) {
      responsesTexte = [intl.formatMessage({ id: 'no', defaultMessage: 'Non' })];
    }
    onAnswer({
      ...context,
      activityGroup: {
        ...getCurrentGroup,
        responsesTexte,
        inputData,
      },
    });
  };

  return <InOut show={true} startTriggering>
    <form onSubmit={submit} key={getCurrentGroup?.question.id}>
      <PaddingForCard>
        {getCurrentGroup && <div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <SpanBody2>{getCurrentGroup.question.label} {getCurrentGroup.question.mandatory ? '*' : ''}</SpanBody2>
            {getCurrentGroup.question.type === 'MULTIPLE_CHOICE' && <div>
              <SpanCaption1> {intl.formatMessage({
                id: 'moreChoice',
                defaultMessage: 'Plusieurs choix possibles',
              })}</SpanCaption1>
            </div>}
            {getCurrentGroup.question.type === 'SINGLE_CHOICE' && <div>
              <SpanCaption1> {intl.formatMessage({
                id: 'oneChoice',
                defaultMessage: 'Un seul choix possible',
              })}</SpanCaption1>
            </div>}
          </div>
          <ActivityQuestion
            questionAnswer={getCurrentGroup}
            onChange={setCurrentGroup} />
        </div>
        }
      </PaddingForCard>
      {
        getCurrentGroup && (getCurrentGroup.question as QuestionChoices).addOverallQuantity && getCurrentGroup.booleanFirst ? <>
          <hr />
          <PaddingForCard>
            <p style={{ textAlign: 'center' }}>
              <SpanBody2>{(getCurrentGroup.question as QuestionChoices).overallQuantityLabel}</SpanBody2>
            </p>
            <InputNumber
              label={((getCurrentGroup.question as QuestionChoices).overallQuantityDescription || '') +
                ((getCurrentGroup.question as QuestionChoices).unit ? ` (${(getCurrentGroup.question as QuestionChoices).unit})` : '')}
              required
              step='any'
              min={1}
              value={getCurrentGroup.overallQuantityResponse || null}
              onChange={(v) => setCurrentGroup({
                ...getCurrentGroup,
                overallQuantityLabel: (getCurrentGroup.question as QuestionChoices).overallQuantityLabel,
                overallQuantityResponse: v || 0,
                overallQuantityUnit: (getCurrentGroup.question as QuestionChoices).unit,
              })}
            />
          </PaddingForCard></> : null
      }
      <FormFooter onCancel={onPrevious} title={intl.formatMessage({ id: 'validate', defaultMessage: 'Valider' })} />
    </form>
  </InOut>;
};
