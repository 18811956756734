import { ImageValue } from '@linkeo.com/ui-lib-react';
import { Question } from './question.types';
import { Category } from './category.types';
import { Choice } from './choice.types';

type Devise = 'EUR' | 'USD' | 'GBP' | 'CHF' | 'AUD' | 'CAD';

export interface ApiPicture {
  filename: string;
  absoluteUrl: string;
}

export interface ApiReferential {
  id: string;
  name: string;
}

export interface EditImage {
  isNew: boolean,
  image: ImageValue;
}

export type Origin = 'REFERENTIAL' | 'CUSTOMER';

export const Scopes = ['TARGETING', 'ACTIVITY', 'ACTIVITY_QUESTIONS', 'CONTACT'] as const;
export type ScopeType = typeof Scopes[number];

export interface SelectedChoice {
  id: string;
  quantity: number;
}

export type ScopeActivity = (Category | Question)[];
export type ApiForm = {
  CONTACT: Question[];
  ACTIVITY: Category[];
  ACTIVITY_QUESTIONS: Question[];
  TARGETING: Question[];
}

export interface ApiAnswerChoice {
  id: string;
  quantity?: number;
}

export interface ApiAnswer {
  id: string;
  answer?: string;
  overallQuantity?: number;
  choices?: ApiAnswerChoice[];
}

export interface GroupedAnswer {
  question: Question;
  responsesTexte?: string[];
  inputData?: any;
  isInvalid?: boolean;
  activityTitle?: string;
  overallQuantityLabel?: string;
  overallQuantityResponse?: number;
  overallQuantityUnit?: string;
  booleanFirst?: boolean;
}

export interface ProgressScope {
  title: string;
  subtitle?: string;
  groups?: GroupedAnswer[];
  categories?: Category[];
  activityGroup?: GroupedAnswer;
  scope: ScopeType;
  activityId?: string;
  isActivityPresentation?: boolean;
  activityImage?: string;
  ineligibilityCustomMessage?: string;
}

export type ChoiceSelection = {
  choice: Choice;
  id: string,
  quantity?: number
}

export const surface = 'surface';
export const volume = 'volume';

export const price = 'price';
export const value = 'value'
type UnitType = typeof price | typeof value;

interface Unit {
  type: UnitType,
  value?: string|null,
  valueCurrency?: string|null
}

export const MANUAL = 'MANUAL';
const BETWEEN = 'BETWEEN';
export const FROM = 'FROM';
export const EXACT = 'EXACT';

export type ParamDisplayType = typeof BETWEEN | typeof FROM | typeof MANUAL | typeof EXACT;
export type ParamTax = 'TVA' | 'HT';
export type ApiEstimation = {
  display: ParamDisplayType,
  details: string,
  tax: ParamTax,
  currency: Devise,
  price?: {
    min: number,
    max: number
  },
  unit: Unit,
  ineligibility?: boolean
}
