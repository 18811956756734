import React, { FC, useEffect, useState } from 'react';
import { Col, Container, InOut, Keyframes, Row, SelectOption } from '@linkeo.com/ui-lib-react';
import { useApi } from '../../providers/api-provider';
import {
  ApiAnswer,
  ApiEstimation,
  ApiForm,
  ChoiceSelection,
  GroupedAnswer,
  ProgressScope,
} from '../../interfaces/api.types';
import { Progress } from '../../components/progress/progress';
import { UpdateItemInArray } from '../../utils/deep-object.utils';
import { FormPresentation } from '../../components/form-builder/form-presentation';
import { Choice } from '../../interfaces/choice.types';
import { EstimationPresentation } from '../../components/estimation/estimation.presentation';
import { useConfirm } from '../../providers/confirm-provider';
import { useIntl } from 'react-intl';
import { PageError } from '../error/_error';
import { PageWrapper } from '../../components';
import { Error404 } from '../error/error-404';
import { QuestionChoices } from '../../interfaces/question.types';
import { IneligibleEstimation } from '../../components/estimation/ineligible-estimation';

interface HomePageProps {
  formId: string;
}

export const HomePage: FC<HomePageProps> = ({ formId }) => {
  const API = useApi();
  const [getForm, setForm] = useState<ApiForm>();
  const [getStep, setStep] = useState<number>(0);
  const [getPath, setPath] = useState<ProgressScope[]>([]);
  const [getEstimation, setEstimation] = useState<ApiEstimation>();
  const [getLoading, setLoading] = useState<boolean>(false);
  const { openConfirm, rootRef } = useConfirm();
  const intl = useIntl();
  const [getFormId, setFormId] = useState<string>();
  const [getFakeLoading, setFakeLoading] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(false);
  const [has404Error, set404Error] = useState<boolean>(false);
  const [isUniqueActivity, setIsUniqueActivity] = useState<boolean>(false);
  const [ineligibleForm, setIneligibleForm] = useState<boolean>(false);
  const [ineligibleStep, setIneligibleStep] = useState<{ message?: string, step: number }>();
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  const makeFakeLoading = async () => {
    return new Promise<void>(resolve => {
      setFakeLoading(true);
      setTimeout(() => {
        setFakeLoading(false);
        resolve();
      }, 300);
    });
  };

  useEffect(() => {
    setFormId(formId);
  }, [formId]);

  useEffect(() => {
    if (!getFormId) {
      return;
    }
    API.getForm(getFormId).then(result => {
      setIsUniqueActivity(result.ACTIVITY.length === 1 && result.ACTIVITY[0].activities.filter(a => a.questions.length !== 0).length === 1);
      setForm(result);
    }).catch(e => {
      if (e.response?.status === 404) {
        set404Error(true);
      } else {
        setError(true);
      }
    });
  }, [API, getFormId]);

  useEffect(() => {
    if (!getForm) {
      return;
    }
    if (getForm.TARGETING.length) {
      setPath([
        {
          scope: 'TARGETING',
          title: intl.formatMessage({
            id: 'targetingScopeTitle',
            defaultMessage: 'Que pouvons-nous faire pour vous ?',
          }),
          groups: getForm.TARGETING.map(q => ({
            question: q,
          })),
          subtitle: '',
        }]);
    }
    if (isUniqueActivity) {
      const selection = getForm.ACTIVITY[0].activities[0];
      setPath((prevState) => [...prevState, {
        title: selection.name,
        scope: 'ACTIVITY',
        activityId: selection.id,
        isActivityPresentation: true,
        activityImage: selection.picture?.absoluteUrl,
      },
        ...selection.questions.map(q =>
          ({
            activityId: selection.id,
            scope: 'ACTIVITY',
            title: intl.formatMessage({
              id: 'activityPageTitle',
              defaultMessage: 'Précisez votre besoin pour {activityName}',
            }, { activityName: selection.name }),
            subtitle: q.label,
            ineligibilityCustomMessage: selection.ineligibilityCustomMessage,
            activityGroup: {
              question: q,
              activityTitle: selection.name,
              booleanFirst: q.type === 'SINGLE_CHOICE' || q.type === 'MULTIPLE_CHOICE' ? !(q as QuestionChoices).booleanFirst : false,
            },
          } as ProgressScope)),
        ...getForm.ACTIVITY_QUESTIONS.map(aq => ({
          scope: 'ACTIVITY_QUESTIONS',
          title: aq.label,
          activityGroup: {
            question: aq,
          },
          subtitle: '',
        } as ProgressScope))
        , {
          scope: 'CONTACT',
          title: intl.formatMessage({
            id: 'contactScopeTitle',
            defaultMessage: 'Encore quelques informations pour recevoir votre estimation !',
          }),
          groups: getForm.CONTACT.map(q => ({
            question: q,
            activityTitle: intl.formatMessage({ id: 'contactScopeSubtitle', defaultMessage: 'Contact' }),
          })),
          subtitle: '',
        },
      ]);
    } else {
      setPath((prevState) => [...prevState, {
        scope: 'ACTIVITY',
        title: intl.formatMessage({ id: 'activityScopeTitle', defaultMessage: 'Que pouvons-nous faire pour vous ?' }),
        categories: getForm.ACTIVITY,
        subtitle: '',
      }]);
    }
  }, [getForm, intl, isUniqueActivity]);

  const activitiesSelected = (activities: ProgressScope[]) => {
    if (!getForm) {
      return;
    }
    const minStep = getForm.TARGETING.length ? 2 : 1;
    const currentPath: ProgressScope[] = getPath.slice(0, minStep);
    currentPath.push(...activities,
      ...getForm.ACTIVITY_QUESTIONS.map(aq => ({
        scope: 'ACTIVITY_QUESTIONS',
        title: aq.label,
        activityGroup: {
          question: aq,
        },
        subtitle: '',
      } as ProgressScope))
      , {
        scope: 'CONTACT',
        title: intl.formatMessage({
          id: 'contactScopeTitle',
          defaultMessage: 'Encore quelques informations pour recevoir votre estimation !',
        }),
        groups: getForm.CONTACT.map(q => ({
          question: q,
          activityTitle: intl.formatMessage({ id: 'contactScopeSubtitle', defaultMessage: 'Contact' }),
        })),
        subtitle: '',
      });
    setPath(currentPath);
    makeFakeLoading().then(() => {
      setStep(getStep + 1);
    });
  };

  const onAnswer = (pq: ProgressScope) => {
    if ((pq.activityGroup?.question.type === 'SINGLE_CHOICE' || pq.activityGroup?.question.type === 'MULTIPLE_CHOICE') && pq.activityGroup?.inputData && pq.activityGroup.inputData.filter((cs: ChoiceSelection) => cs.choice.eligibility === false).length > 0) {
      setIneligibleStep({ message: pq.ineligibilityCustomMessage, step: getStep });
      if (getForm?.CONTACT && getForm?.CONTACT.length > 0) {
        makeFakeLoading().then(() => {
          setPath(UpdateItemInArray(getPath, getStep, pq));
          setStep(getPath.length - 1);
        });
      } else {
        makeFakeLoading().then(() => setIneligibleForm(true));
      }
    } else {
      setIneligibleStep(undefined);
      makeFakeLoading().then(() => {
        const step = getStep;
        setPath(UpdateItemInArray(getPath, step, pq));
        setStep(step + 1);
      });
    }
  };

  const groupToApiAnswer = (g: GroupedAnswer): ApiAnswer | undefined => {
    const id = g.question.id;
    if (!g.inputData) {
      return;
    }
    const selectedChoice = (g.inputData as SelectOption<Choice>).value;
    switch (g.question.type) {
      default:
      case 'SHORT_TEXT' :
      case  'LONG_TEXT' :
      case 'QUANTITY' :
        return {
          id,
          answer: g.inputData,
        };
      case 'SINGLE_CHOICE' :
      case'MULTIPLE_CHOICE':
        if (g.inputData.length) {
          return {
            id,
            overallQuantity: g.overallQuantityResponse,
            choices: (g.inputData as ChoiceSelection[]).map((c) => ({ id: c.id, quantity: c.quantity })),
          };
        }
        if (selectedChoice) {
          return {
            id,
            overallQuantity: g.overallQuantityResponse,
            choices: [{ id: selectedChoice.id }],
          };
        }
    }
  };

  const onFinish = (pq: ProgressScope) => {
    setLoading(true);
    const stepFinal: ProgressScope[] = UpdateItemInArray(getPath, getStep, pq);
    const answers: (ApiAnswer | undefined)[] = stepFinal.flatMap((step) => {
      if (step.groups) {
        return step.groups.map((g) => groupToApiAnswer(g));
      }
      if (step.activityGroup) {
        return [groupToApiAnswer(step.activityGroup)];
      }
      return [];
    });
    const withoutUndefined: ApiAnswer[] = answers.filter(a => typeof a !== 'undefined') as ApiAnswer[];
    const activities = stepFinal.map(sf => sf.activityId).filter(a => typeof a !== 'undefined') as string[];
    if (!getFormId) {
      return;
    }
    API.postAnswers(getFormId, {
      questions: withoutUndefined,
      activities: Array.from(new Set(activities)),
    }).then((response) => {
      setPath(stepFinal);
      setStep(getPath.length);
      setEstimation(response);
      setSelectedActivities(Array.from(new Set(activities)));
    }).catch(() => {
      setError(true);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getQuestionAnswerList = () => {
    const groupedAnswers: GroupedAnswer[] = [];
    getPath.forEach(p => {
      if (p.groups) {
        groupedAnswers.push(...p.groups);
      }
      if (p.activityGroup) {
        groupedAnswers.push(p.activityGroup);
      }
    });
    return groupedAnswers;
  };

  const goNext = () => {
    makeFakeLoading().then(() => {
      setStep(getStep + 1);
    });
  };

  const goBack = () => {
    if (!getForm) {
      return;
    }
    const previousStep = ineligibleStep?.step ?? getStep - 1;
    const minStep = getForm.TARGETING.length ? 1 : 0;
    if (previousStep === minStep) {
      openConfirm({
        title: '',
        content: intl.formatMessage({
          id: 'confirmReturn',
          defaultMessage: 'êtes vous sûre de vouloir revenir à cette page ? (les informations précédemment renseignées seront effacées)',
        }),
      }).then(() => {
        makeFakeLoading().then(() => {
          setStep(previousStep);
        });
      });
    } else {
      makeFakeLoading().then(() => {
        setStep(previousStep);
      });
    }
    setIneligibleStep(undefined);
  };

  return (
    has404Error ? <Error404 /> : hasError ? <PageError />
      : <PageWrapper isLoading={!getForm}>
        <Container size={1024} ref={rootRef}>
          <Row justifyContent={['space-between']} wraps={['wrap']} alignItems={['flex-start']}>
            <Col columns={[12, 12, 12, 5]} style={{ marginBottom: '10px' }}>
              <Progress
                questionAnswers={getQuestionAnswerList()}
                progressPercent={ineligibleForm ? 100 : (getStep && getPath.length && !getPath[getStep]?.categories) ? getStep / getPath.length * 100 : 5}
              />
            </Col>
            <Col columns={[12, 12, 12, 7]}>
              <InOut show={!getFakeLoading}
                     enter={{ keyframes: Keyframes.slideInFrom('50px') }} keepContent={true}>
                {ineligibleForm || getEstimation?.ineligibility
                  ? <IneligibleEstimation ineligibleMessage={ineligibleStep?.message} />
                  : getEstimation
                    ? <EstimationPresentation
                      type={getForm?.ACTIVITY[0].formType}
                      eligibilityMessages={getForm?.ACTIVITY.flatMap(act => act.activities.map(a => !!selectedActivities.find(el => el === a.id) && a.eligibilityCustomMessage || ''))}
                      result={getEstimation} />
                    : <FormPresentation
                      noTargeting={!getForm?.TARGETING.length}
                      onAnswer={onAnswer}
                      progress={getPath[getStep]}
                      onPrevious={goBack}
                      onNext={goNext}
                      onActivitiesSelected={activitiesSelected}
                      onFinish={onFinish}
                      isLoading={getLoading}
                      isUniqueActivity={isUniqueActivity}
                    />}
              </InOut>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
  );
};
